import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';

const CatGrooming = ({ data }) => {
  var pageTitle = 'Cat Grooming';

  return (
    <Layout
      slug="grooming"
      title={pageTitle}
      description="Find out more about the grooming process with our internationally certified cat groomer"
    >
      <h1>{pageTitle}</h1>

      <p>
        We are proud to announce that we have an internationally certified cat
        groomer on staff. Tiana is trained in many different styles from the
        lion cut to dragon trim. In addition, all of our pet groomers are
        trained in safety and calming techniques designed to create a safe and
        relaxing environment for your feline as they get pampered. To help
        create a calm environment cat grooming is usually done one on one at a
        time when there are no other pets in the grooming area. At times it may
        be necessary for owners to stay and help during the procedure to help
        their feline feel more comfortable.
      </p>
      <p>
        We offer many different services such as full grooms, sanitary trims,
        mat removal, nail trims, and more!
      </p>

      <section className="flex flex-col items-center text-center mb-20">
        <span className="h2">
          Wondering who will be working with your pets?
        </span>
        <Link to="/about/#grooming-team" className="btn btn-light">
          Meet the Grooming Team
        </Link>
      </section>

      <div className="flex flex-wrap justify-around items-center">
        <GatsbyImage
          image={getImage(data.image2)}
          alt="groomed cat"
          className="order-1 w-full md:w-2/7 mb-4 rounded-xl"
          style={{ maxWidth: 800 }}
        />
        <GatsbyImage
          image={getImage(data.image1)}
          alt="groomed cat"
          className="order-1 w-full md:w-2/7 mb-4 rounded-xl"
          style={{ maxWidth: 800 }}
        />
        <GatsbyImage
          image={getImage(data.image3)}
          alt="groomed cat"
          className="order-1 w-full md:w-2/7 mb-4 rounded-xl"
          style={{ maxWidth: 800 }}
        />
      </div>

      <Link
        className="btn btn-light mr-3"
        to="/grooming-rates/#feline-groom-rates"
      >
        Grooming Rates
      </Link>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cat_grooming3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "cat_grooming2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "cat_grooming1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default CatGrooming;
